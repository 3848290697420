import React, { useEffect, useState } from 'react';
import { fetchGet, fetchPost } from '../request';
import { SideBar } from "../SideBar";
import './report.css';

export function ManangerInfoPage() {
    const [userInfo, setUserInfo] = useState({});

    // Fetch user information and parking lot times on component mount
    useEffect(() => {
        fetchGet('/api/account/profile', {}, (data) => {
            setUserInfo(data);
        });
    });

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <SideBar username={userInfo.username} />
                </div>
                <div className="col-md-8">
                    <div className="mt-4">
                        <h3>Instructions for Updating Parking Lot Times</h3>
                        <p>To change the active time of the parking lot, please contact the developer. The following variables need to be updated:</p>
                        <ul>
                            <li>
                                In the file located at:
                                <pre><code>~/directory_of_project/parkingweb/.env</code></pre>
                                Update:
                                <ul>
                                    <li><code>REACT_APP_START_HOUR</code></li>
                                    <li><code>REACT_APP_END_HOUR</code></li>
                                </ul>
                                <p>Values are in 24-hour format. After making the changes and saving them, the frontend server needs to be restarted.</p>
                            </li>
                            <li>
                                In the file located at:
                                <pre><code>~/directory_of_project/setting.py</code></pre>
                                Update:
                                <ul>
                                    <li><code>DAY_START = time(8, 0)  # 8:00 AM</code></li>
                                    <li><code>DAY_END = time(17, 0)  # 5:00 PM</code></li>
                                </ul>
                                <p>After making the changes and saving them, the backend server needs to be restarted as well.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManangerInfoPage;
