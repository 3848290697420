import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import './signIn.css';
import CMULogo from '../../images/CMULogo.png';
import ParkingLogo from '../../images/ParkingLogo.png';
import { TextField, Button, Grid, Typography, Container, Link } from '@mui/material';
import {fetchGet, fetchPost} from "../request"
// Import the environment variable
const isProd = process.env.REACT_APP_PROD === 'true';

// Set the host based on the environment
const httpval = isProd ? 'https://' : 'http://';
export function ForgetPassword(props) {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');
    const [pwd, setPwd] = useState('');
    const [open, setOpen] = useState(false);
    const [sever, setSever] = useState('');
    const [message, setMessage] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const history = useHistory();

    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const handleConfirm = (event) => {
        const newConfirmPwd = event.target.value;
        setConfirmPwd(newConfirmPwd);
        validateConfirmPassword(pwd, newConfirmPwd);
    };

    const validateConfirmPassword = (password, confirmPassword) => {
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            return false;
        }
        setConfirmPasswordError('');
        return true;
    };

    const handleSignIn = (event) => {
        event.preventDefault();
        history.push('signin');
    };
    const handleSubmit = (event) => {
        event.preventDefault();
    
        const params = {
            email: email,
        };
    
        fetch(httpval + localStorage.getItem('host') + '/api/forget_password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params),
        })
        .then((response) => {
            if (!response.ok) {
                return response.json().then((err) => {
                    throw new Error(JSON.stringify(err));
                });
            }
            return response.json();
        })
        .then((data) => {
            if (data.msg === 'Account does not exist') {
                setSever('warning');
                setMessage('Account does not exist!');
            } else if (data.msg) {
                setSever('success');
                setMessage('A new password has been emailed to you.');
            } else if (data.error) {
                setSever('error');
                setMessage('Something went wrong.');
            }
            setOpen(true);
        })
        .catch((error) => {
            console.error('Error during password reset:', error);
            setSever('error');
            setMessage('An unexpected error occurred.');
            setOpen(true);
        });
    };
    
    
    // const updateCallback = (data) => {
    //     if (data.msg === 'Account does not exist') {
    //         setSever('warning');
    //         setMessage('Account does not exist!');
    //         setOpen(true);
    //     } else if (data.msg) {
    //         setSever('success');
    //         setMessage('Successfully New Password is emailed to you.');
    //         setOpen(true);
    //     } else if (data.error) {
    //         setSever('error');
    //         setMessage('Something went wrong.');
    //         setOpen(true);
    //     }
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault();

    //     const params = {
    //         email: email
    //     };

    //     fetchPost('/api/forget_password', params, updateCallback);
    // };
    const getStatusClassName = () => {
        switch (sever) {
            case 'success':
                return 'alert alert-success';
            case 'warning':
                return 'alert alert-warning';
            case 'error':
                return 'alert alert-danger';
            default:
                return '';
        }
    };
    const handleBack = (event) => {
        event.preventDefault();
        // Redirect to signin page
        history.push('signin');
    };

    return (
        <div>
            <div className='row g-0 mx-0'>
                <div className='col-md-5 vh-100 px-0'>
                    <h1 className='reserveParking'>Reserve Your Parking Space Today!</h1>
                </div>

                <div className='col-md-7 vh-100 d-flex align-items-center justify-content-center'>
                <img src={ParkingLogo} alt='Parking Logo' style={{ width: '7%', height: 'auto', maxWidth: '100%' }} />

                                <Typography variant="h5" align="center" gutterBottom sx={{ color: 'black', fontWeight: 'bold', marginTop: '1rem' }}>
                                    Forgot Password
                                </Typography>

                    <form onSubmit={handleSubmit}>
                        <div className='row mb-3'>
                        </div>
                        <div className='row mb-3'>
                            <label htmlFor='inputEmail' className='col-sm-4 col-form-label'>
                                Email *
                            </label>
                            <div className='col-sm-7'>
                                <input
                                    type='email'
                                    className='form-control'
                                    id='inputEmail'
                                    value={email}
                                    onChange={handleEmail}
                                />
                            </div>
                        </div>
                        <div className='button-container'>
                            <button className='btn btn-primary' type='submit'>
                                Reset Password
                            </button>
                            <p className='sign-in-link' onClick={handleSignIn}>
                                Already a user? Sign In
                            </p>
                        </div>
                    </form>
                    {/* Status message rendered here */}
                    {message && (
                        <div className={getStatusClassName()} style={{ marginTop: '20px' }}>
                            {message}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
