import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import './history.css';
//cmttmp
export function HistoryCard(props) {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <div className="history-card" onClick={handleShowModal}>
                <div className="history-card-date">
                    <p>{props.date}</p>
                </div>
                <div className="history-card-info">
                    <p><strong>Spot {props.spot}</strong> {props.parkingLot}</p>
                    <p>{props.startTime} - {props.endTime}</p>
                </div>
                <div className="history-card-price">
                    <p>${props.price-props.refund} </p>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reservation Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Date:</strong> {props.date}</p>
                    <p><strong>Spot:</strong> {props.spot}</p>
                    <p><strong>Parking Lot:</strong> {props.parkingLot}</p>
                    <p><strong>Reservation Time:</strong> {props.startTime} - {props.endTime}</p>
                    <p><strong>Reservation Fee:</strong> ${props.reservationFee}</p>
                    <p><strong>Trip Charge:</strong> ${props.charge}</p>
                    <p><strong>Total Cost:</strong> ${props.price}</p>
                    {props.refund != "0.00" ? <p><strong>Refund Amount:</strong> ${props.refund}</p> :<p></p>}
                    {props.isCompleted && <p><strong>Status:</strong> Completed</p>}
                    {props.isCanceled && <p><strong>Status:</strong> Canceled</p>}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
