import React, { useEffect, useState } from 'react';
import { fetchPost } from '../request';
import './active.css';
import { ReportForm } from '../Report/ReportForm';
import { ExtendForm } from './ExtendForm';
import CheckoutModal from './CheckoutModal';

const checkInAdvance = 15 * 60 * 1000; // 15 minutes in milliseconds
const formatNum = (num) => (num < 10 ? '0' + num : num);

export function ActiveCard(props) {
    const [prompt, setPrompt] = useState(<span>Wait for spot allocation</span>);
    const [promptUpdated, setPromptUpdated] = useState(false);
    const [didCheckIn, setCheckIn] = useState(props.spot !== null || props.isCompleted || props.isCanceled);
    const [completed, setCompleted] = useState(props.isCompleted);
    const [canceled, setCanceled] = useState(props.isCanceled);
    const [spot, setSpot] = useState(props.spot);

    const [scheduledLeaveTime, setScheduledLeaveTime] = useState(props.scheduledLeaveTime);
    const [extendModalShow, setExtendModalShow] = useState(false);
    const [actionModalShow, setActionModalShow] = useState(false);
    const [actionPrompt, setActionPrompt] = useState(<p></p>);
    const [showReportForm, setShowReportForm] = useState(false);
    const [confirmModalShow, setConfirmModalShow] = useState(false);
    const [confirmPrompt, setConfirmPrompt] = useState(<p></p>);
    const [action, setAction] = useState("cancel");

    const handleShowReportForm = () => setShowReportForm(true);
    const handleCloseReportForm = () => setShowReportForm(false);

    const handleActionShow = () => setActionModalShow(true);
    const handleActionClose = () => {
        setActionModalShow(false);
        setActionPrompt(<p></p>);
        setTimeout(() => props.updateHandle(), 800);
    };

    const handleExtendShow = () => setExtendModalShow(true);
    const handleExtendClose = () => setExtendModalShow(false);

    const handleConfirmShow = () => setConfirmModalShow(true);
    const handleConfirmClose = () => setConfirmModalShow(false);

    const handleCancelClick = () => {
        setConfirmPrompt(<p>Cancel the Reservation?</p>);
        setAction('cancel');
        handleConfirmShow();
    };

    const handleCheckOutClick = () => {
        setConfirmPrompt(<p>Check out this session?</p>);
        setAction('checkout');
        handleConfirmShow();
    };

    const getRemainingTime = () => {
        const now = new Date();
        const timeDiff = props.scheduledLeaveTime - now;
        const hours = Math.floor(timeDiff / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        return {
            timeString: `${formatNum(hours)}:${formatNum(minutes)}:${formatNum(seconds)}`,
            timeDiff,
        };
    };

    const [remainingTime, setRemainingTime] = useState(getRemainingTime().timeString);

    useEffect(() => {
        const timer = setInterval(() => {
            const { timeString, timeDiff } = getRemainingTime();
            setRemainingTime(timeString);
            if (timeDiff <= 0) {
                clearInterval(timer);
                props.updateHandle();
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [props.scheduledLeaveTime]);

    useEffect(() => {
        let waitTime = props.checkInTime - Date.now();
        waitTime = waitTime <= checkInAdvance ? 0 : waitTime - checkInAdvance;
        const params = { 'username': localStorage.getItem('username'), 'tranId': props.id };

        const getSpotCallback = (data) => {
            if ('spot' in data) {
                setSpot(data['spot']);
                setPrompt(<span>Spot reserved for you: <strong>{data['spot']}</strong></span>);
                setPromptUpdated(false);
            } else {
                setTimeout(() => setCheckIn(false), 15000);
            }
        };

        if (spot == null && !didCheckIn) {
            setCheckIn(true);
            setTimeout(() => fetchPost('/api/reservation/allocate', params, getSpotCallback), waitTime);
        }
    });

    useEffect(() => {
        if (!promptUpdated) {
            if (completed) setPrompt(<span>Completed</span>);
            else if (canceled) setPrompt(<span>Canceled</span>);
            else if (spot !== null) setPrompt(<span>Spot reserved for you: <strong>{spot}</strong></span>);
            else setPrompt(<span>Wait for spot allocation</span>);
            setPromptUpdated(true);
        }
    });

    const handleCancel = () => {
        const params = {
            'username': localStorage.getItem('username'),
            'tranId': props.id,
            'action': 'cancel',
            'checkInTime': props.checkInTime,
            'scheduledLeaveTime': props.scheduledLeaveTime,
            'parkingLot': props.parkingLot
        };

        const cancelCallback = (data) => {
            if ('error' in data) {
                setActionPrompt(<p>{data['error']}</p>);
                handleActionShow();
            } else {
                setActionPrompt(<p>You successfully canceled a reservation!
                    <br /> The parking charge: <strong>${data['refund']}</strong>
                    <br /> will be returned to your balance within two workdays.
                </p>);
                handleActionShow();
            }
        };

        fetchPost('/api/reservation/action', params, cancelCallback);
    };

    return (
        <div className="card active-card">
            <div className="card-body d-flex justify-content-between align-items-center flex-wrap">
                <div className="active-card-info">
                    <p className="active-card-location">{props.parkingLot}</p>
                    <p className="active-card-location">Spot: {props.spot}</p>
                    <div className="active-card-time-info">
                        <p>{props.startTime}</p>
                        <p>{props.date}</p>
                    </div>
                    <div className="active-card-time-info">
                        <p>{props.endTime}</p>
                        <p>{props.date}</p>
                    </div>
                </div>
                <div className="active-card-timer-container">
                    <div className="active-card-timer text-center">
                        <span className="active-card-time">{remainingTime}</span>
                        &nbsp;<span>left</span>
                    </div>
                </div>
                <div className="active-card-actions">
                    <p className="active-card-report-violation" onClick={handleShowReportForm}>Report Violation</p>
                    <button className="btn btn-danger" onClick={handleCheckOutClick}>Leave</button>
                    <button className="btn btn-danger" onClick={handleExtendShow}>Extend</button>
                </div>
            </div>
            <div className="action-prompt-container">
                {actionPrompt}
            </div>
            <CheckoutModal
                show={confirmModalShow}
                handleClose={handleConfirmClose}
                confirmPrompt={confirmPrompt}
                handleConfirm={() => {
                    if (action === 'cancel') handleCancel();
                    else if (action === 'checkout') handleCheckOutClick();
                    handleConfirmClose();
                }}
                action={action}
                reservation={{
                    id: props.id,
                    checkInTime: props.checkInTime,
                    scheduledLeaveTime: props.scheduledLeaveTime,
                    parkingLot: props.parkingLot
                }}
                setActionPrompt={setActionPrompt}
                handleActionShow={handleActionShow}
                handleConfirmClose={handleConfirmClose}
            />

            <ExtendForm
                show={extendModalShow}
                handleClose={handleExtendClose}
                reservation={{
                    id: props.id,
                    checkInTime: props.checkInTime,
                    scheduledLeaveTime: props.scheduledLeaveTime,
                    parkingLot: props.parkingLot
                }}
                updateHandle={props.updateHandle}
            />

            <ReportForm 
                show={showReportForm} 
                handleClose={handleCloseReportForm} 
                parkingLot={props.parkingLot} 
                spot={spot} 
                userInfo={{ username: localStorage.getItem('username'), tel: localStorage.getItem('userPhone') }} 
                uploadData={fetchPost} 
            />
        </div>
    );
}
