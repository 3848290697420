import React, { useState, useEffect } from 'react';
import { SideBar } from './SideBar';
import { fetchGet, fetchPost } from './request';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import '../App.css';

export function PaymentPage(props) {
    const [balance, setBalance] = useState("$ 0.00");
    const [didUpdate, setDidUpdate] = useState(false);
    const [coupon, setCoupon] = useState('');
    const [loading, setLoading] = useState(false); // Added loading state


    const handleCouponInput = (event) => {
        setCoupon(event.target.value); // Update coupon state as user types
    };

    const clearCoupon = () => {
        setCoupon(''); // Clear the coupon input after the backend call
    };

    const redeemCallback = (data) => {
        if ('balance' in data) {
            setBalance(data['balance']);
            alert("Successfully redeemed the coupon.");
        } else {
            alert(data['error']);
        }
        setLoading(false)
        clearCoupon(); // Clear the input after success or failure
    };

    const handleRedeem = (event) => {
        event.preventDefault();
        setLoading(true)
        const params = {
            "username": localStorage.getItem("username"),
            "promotionCode": coupon
        };
        fetchPost('/api/payment/redeem', params, redeemCallback);
    };

    const updateCallback = (data) => {
        if ('balance' in data) {
            setBalance("$ " + Number(data['balance']).toFixed(2));
        } else {
            setDidUpdate(false);
        }
    };

    useEffect(() => {
        if (didUpdate) {
            return;
        }
        setDidUpdate(true);
        fetchGet('/api/account/profile', {}, updateCallback);
    });

    return (
        <Container fluid>
            <Row>
                <Col md={3}>
                    <SideBar username={localStorage.getItem("username")} />
                </Col>
                <Col md={{ span: 6, offset: 1 }} className="my-3 border p-4">
                    <Form>
                        <Form.Group as={Row} className="my-4">
                            <Form.Label htmlFor='balance' className='col-lg-2 col-form-label font-weight-bold'>
                                Balance
                            </Form.Label>
                            <Col lg={6}>
                                <Form.Control type='text' id='balance' value={balance} style={{ width: "100%" }} disabled />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="my-4">
                            <Form.Label htmlFor='promotion' className='col-lg-2 col-form-label font-weight-bold'>
                                Coupon
                            </Form.Label>
                            <Col lg={6}>
                                <Form.Control 
                                    type='text' 
                                    placeholder='Enter your promotion code' 
                                    id='promotion' 
                                    style={{ width: "100%" }} 
                                    value={coupon}  // Controlled input tied to the coupon state
                                    onChange={handleCouponInput} 
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="my-4">
                            <Col lg={6} className='pt-1'>
                                <Button className='btn btn-info' onClick={handleRedeem} disabled={loading}>{loading ? 'Submitting':'Redeem'}</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default PaymentPage;
