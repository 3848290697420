import React from 'react';
import { Container, Grid, Link, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
// import { Sidebar } from "../components/sidebar";
// import '../styles/style.css';


export function FAQ() {
    const faqs1 = [
        {
            question: "What is it?",
            answer: "It’s a new idea for parking that allows you to reserve parking online and in advance, taking advantage of a more efficient and convenient online management system."
        },
        {
            question: "How do I register?",
            answer: "It’s easy, go to the web application at: <a href='https://parking-pilot.cmu.edu/'>https://parking-pilot.cmu.edu/</a>. Complete the registration form and you will receive an email with your login information. Once registered, you can log in to the web application, check your parking credit balance, and start making parking reservations."
        },
        {
            question: "Is there a cost?",
            answer: "In phase one, a participant will use his/her respective account balance toward parking fees. The account balance is initialized by a parking credit of $100, then replenished by applying for additional parking credits. The issuance of additional parking credits will be contingent on the participation in user experience interviews. Therefore, a participant will pay a ‘fee’ to park, but technically the actual cost to them is nothing. The mock ‘fee’ will help the research team understand parking behavior"
        },]
    const faqs2 = [
        {
            question: "How do I reserve a space?",
            answer: "It’s easy! Log in to your account on the web application and click <b>Reserve</b>. Fill out the date and check-in/-out time, as well as confirm your vehicle. Reservations should be made at the Morewood Parking Lot, and payment will be deducted from your parking credit balance. Click <b>Reserve!</b> to reserve!"
        },
        {
            question: "How far in advance can I reserve a space?",
            answer: "You can reserve a space any time for the duration of Phase I (ending October 31, 2024)"
        },
        {
            question: "Can I park there without a reservation?",
            answer: "Yes! If there are spaces available when you arrive at the lot, log in to the web application and click <b>Park Now</b>. You’ll be prompted to select the parking duration and your parking credit balance will be deducted accordingly."
        },
        {
            question: "How do I report a violation?",
            answer: "On the web application, click <b>Report Now</b>. Click on the appropriate parking space, and include the parking violator’s license plate number and a photo of the violation. You will receive an email with further instructions, including parking credit added if you didn’t have a parking reservation, and a new space assignment and/or parking credit if you had a reservation in the space being reported."
        },
        {
            question: "What happens if someone is parked in my space?",
            answer: "Please follow the instructions above to report the violation. You will receive either; an alternative space if available, or a refund and future parking credit if no space is available."
        },
        {
            question: "What if I decide to leave early? Can I get a refund?",
            answer: "Yes. Log in to the web application and <b>Leave</b>. Parking credit will be added to your account, minus a small fee."
        },
        {
            question: "What is the cost of participating in this Parking Pilot?",
            answer: "At this time, from-to (dates, to be filled once we have the start date), we will issue you a $100 parking credit when you sign up the pilot. You can use this credit balance towards your parking in the Pilot area, which covers the standard CMU parking rates and additional parking reservation fee (refer fee schedule above). Provided that you use the credit reasonably, and provide feedback to our follow up questions, additional parking credits may be issued to you for continuation in this pilot study. You can call CMU Parking Service for the issuance of an additional parking credit."
        },
        {
            question: "What if I run out of parking credits?",
            answer: "Please contact <b>tartanparking@gmail.com</b> to request additional credits during phase one."
        },
        {
            question: "What sort of data will be collected and how will it be used?",
            answer: "In order to use the service, personally identifiable information including your consent form, name, and vehicle registration information will be collected, as well as possible financial information in later phases. Use data will also be collected, and feedback will be elicited in either written communication or via phone or video conference. All personally identifiable information will be removed as data is used for research purposes. User data will inform the development of the project and optimize parking management services at CMU. Please see the online consent form for further details."
        }
    
    ];
    const rows1 = [{name: 'Every 15 minutes', fee: '$1.00'}, {name: 'Every 15 minutes (After Hours)', fee: '$0.25'}, {name: 'Daily Maximum', fee: 'None'},{name: '24+ Hours', fee: 'Prohibited, will tow'}]
    const rows2 = [{name: 'Peak', fee: '$5.00'},{name: 'Non-Peak', fee: '$3.00'},{name: 'After-Hours', fee: '$1.00'}]
    return (
        <Container maxWidth="md">
            {/* <Sidebar username={localStorage.getItem('username')} /> */}
            <Grid container spacing={2} className="faq-grid">
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <br/>
                    <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        color="textPrimary"
                        gutterBottom
                    >
                        Frequently Asked Questions
                    </Typography>
                </Grid>
                <Container maxWidth="md">
                    {faqs1.map((faq, index) => (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                            <Typography variant="h5" component="h4" gutterBottom>
                                {faq.question}
                            </Typography>
                            <Typography variant="body1" component="p" gutterBottom dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography variant="h5" component="h4" gutterBottom>
                                What is the parking ‘fee’?
                            </Typography>
                            <Typography variant="body1" component="p" gutterBottom>
                                For a full fee schedule, please see below.
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                    {rows1.map((row) => (
                                        <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.fee}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <Typography variant="body1" component="p" gutterBottom >
                            Parking spaces are enforced 24/7. There is no additional charge for walk-in parking.
There is an additional charge for advanced reservation. This fee will be clearly presented during the parking reservation process. The peak and non-peak will be determined by parking demand, e.g. admission day, conference day.
                            </Typography>
                            <br />
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                    {rows2.map((row) => (
                                        <TableRow key={row.name}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.fee}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <Typography variant="body1" component="p" gutterBottom >
                                If checking out early, half of any remaining fees paid will be returned in the form of future parking credit.
                            </Typography>
                             
                    </Grid>
                    {faqs2.map((faq, index) => (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
                            <Typography variant="h5" component="h4" gutterBottom>
                                {faq.question}
                            </Typography>
                            <Typography variant="body1" component="p" gutterBottom dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        </Grid>
                    ))}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <Typography variant="h5" component="h4" gutterBottom>
                            You can access the consent form by downloading it through the following link: 
                            <br/>
                            <Link href="/consentform.pdf" download>
                                <Typography variant="body1">
                                    Download Consent Form
                                </Typography>
                            </Link>
                        </Typography>
                    </Grid>
                </Container>
            </Grid>
        </Container>
    );
}


export default FAQ;