import React, { useState, useEffect } from 'react';
import { fetchGet, fetchPost } from '../request';
import './extendform.css';
import { Button } from 'react-bootstrap';

export function ExtendForm({ show, handleClose, reservation, updateHandle }) {
    const [extendTime, setExtendTime] = useState('');
    const [extendOptions, setExtendOptions] = useState([]);
    const [newPrice, setNewPrice] = useState(0);
    const [showPriceDetails, setShowPriceDetails] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [showAlertExtend, setShowAlertExtend] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);


    const getExtendOptionsCallback = (data) => {
        let mxExtension = 0;
        let allOptions = [];
        if ('error' in data) {
            // Handle error (not displayed in the picture)
        } else {
            mxExtension = data['extension'];
            if (mxExtension === 0) {
                allOptions.push('No available spots this time.');
            } else {
                let startTime = new Date(reservation.scheduledLeaveTime.getTime() + 15 * 60 * 1000);
                for (let i = 0; i < mxExtension / 15; i++) {
                    allOptions.push(formatNum(startTime.getHours()) + ":" + formatNum(startTime.getMinutes()));
                    startTime = new Date(startTime.getTime() + 15 * 60 * 1000);
                }
            }
            setExtendOptions(allOptions);
        }
    };

    useEffect(() => {
        if (show) {
            fetchGet('/api/reservation/extension', { 'tranId': reservation.id }, getExtendOptionsCallback);
        }
    }, [show, reservation.id]);

    const handleExtend = () => {
        const [newHour, newMin] = extendTime.split(":").map(Number);
        const extendedTime = new Date(reservation.scheduledLeaveTime);
        extendedTime.setHours(newHour);
        extendedTime.setMinutes(newMin);
        setIsSubmitting(true);  // Start submission

        const params = {
            'username': localStorage.getItem('username'),
            'tranId': reservation.id,
            'action': 'extend',
            'checkInTime': reservation.checkInTime,
            'scheduledLeaveTime': extendedTime,
            'parkingLot': reservation.parkingLot
        };

        fetchPost('/api/reservation/action', params, (data) => {
            if ('id' in data) {
                setShowAlert(true);
                setTimeout(() => {
                    setShowAlert(false);
                    setIsSubmitting(false);
                    handleClose();
                    updateHandle();
                }, 3000); // 3 seconds
            } else if ('exceed' in data){
                console.log('this is the error in exceed')
                setShowAlertExtend(true);
                setTimeout(() => {
                    setShowAlertExtend(false);
                    setIsSubmitting(false);
                    handleClose();
                    updateHandle();
                }, 3000); 
            } 
            else {
                console.log(data)
                // Handle error (not displayed in the picture)
            }
        });
    };

    const handlePriceDetails = () => {
        setShowPriceDetails(true);
    };

    const handleExtendTimeChange = (e) => {
        const selectedTime = e.target.value;
        setExtendTime(selectedTime);
        const quarterMultiplier = extendOptions.indexOf(selectedTime);
        setNewPrice((quarterMultiplier + 1) * 1); // Assuming each 15-minute extension costs $5, adjust as necessary
    };

    const formatNum = (num) => (num < 10 ? '0' + num : num);

    return (
        <div className={`modal ${show ? 'show' : ''}`}>
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Extend Your Parking Time</h2>
                </div>
                <div className="modal-body">
                    <label>End Time <span>(only available times are displayed)</span></label>
                    <select value={extendTime} onChange={handleExtendTimeChange}>
                        <option value="" disabled>Choose your parking time...</option>
                        {extendOptions.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                    <div className="price-info">
                        <p>New Price: ${newPrice}</p>
                        <button onClick={handlePriceDetails}>View price details</button>
                    </div>
                </div>
                {showPriceDetails && (
                    <div className="price-details">
                        <p>Price Calculation:</p>
                        <p>Base Price: ${1}</p> {/* Assuming $5 per 15 minutes */}
                        <p>Quarters Extended: {extendOptions.indexOf(extendTime) + 1}</p>
                        <p>Total Price: ${newPrice}</p>
                    </div>
                )}
                {showAlert && (
                    <div className="alert alert-success" role="alert">
                        Extension was successful!
                    </div>
                )}
                {showAlertExtend && (
                    <div className="alert alert-error" role="alert">
                        Extension limit exceeded
                    </div>
                )}
                <div className="modal-footer">
                    <Button className="cancel" onClick={handleClose}>Cancel</Button>
                    <Button
                        className="confirm"  
                        onClick={handleExtend} 
                        disabled={isSubmitting}>
                        {isSubmitting ? "Submitting..." : "Confirm"}
                    </Button>
                </div>
            </div>
        </div>
    );
}
