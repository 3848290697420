import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Assuming you're using React-Bootstrap for modals
import { fetchPost } from '../request';

function ReleaseSpotCard(props) {
    const [showModal, setShowModal] = useState(false);
    const [isReleasing, setIsReleasing] = useState(false);

    const handleCardClick = () => {
        if (props.plate !== 'RESERVED') {
            // Only show modal if the plate is RESERVED
            return;
        }
        setShowModal(true);
    };

    const handleConfirmRelease = (e) => {
        e.preventDefault(); // If the function is triggered by a form submission
        setIsReleasing(true);
    
        const releaseParams = {
            spot: props.spot
        };
    
        const releaseSpotCallback = (data, success) => {
            console.log(data)
            console.log(success)
            if (data.success) {
                alert(`Spot ${props.spot} has been successfully released.`);
                props.refreshSpots(); 
            } else {
                alert(`Failed to release spot ${props.spot}: ${data.message || 'Unknown error'}`);
            }
            setIsReleasing(false);
            setShowModal(false);
        };
    
        fetchPost('/api/reservation/release_spot', releaseParams, releaseSpotCallback);
    };
    

    return (
        <>
            <div 
                className={`card ${props.plate ? 'bg-danger text-white' : 'bg-success text-white'} card-custom`} 
                style={{ cursor: 'pointer' }} 
                onClick={handleCardClick}
            >
                <div className="card-body d-flex flex-column align-items-center justify-content-center">
                    <h5 className="card-title">Spot: {props.spot}</h5>
                    <p>{props.plate}</p>
                    {props.plate ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-car-front mb-2" viewBox="0 0 16 16">
                            <path d="M4 9a1 1 0 1 1-2 0 1 1 0 0 1 2 0m10 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2zM4.862 4.276 3.906 6.19a.51.51 0 0 0 .497.731c.91-.073 2.35-.17 3.597-.17s2.688.097 3.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 10.691 4H5.309a.5.5 0 0 0-.447.276"/>
                            <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679q.05.242.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.8.8 0 0 0 .381-.404l.792-1.848ZM4.82 3a1.5 1.5 0 0 0-1.379.91l-.792 1.847a1.8 1.8 0 0 1-.853.904.8.8 0 0 0-.43.564L1.03 8.904a1.5 1.5 0 0 0-.03.294v.413c0 .796.62 1.448 1.408 1.484 1.555.07 3.786.155 5.592.155s4.037-.084 5.592-.155A1.48 1.48 0 0 0 15 9.611v-.413q0-.148-.03-.294l-.335-1.68a.8.8 0 0 0-.43-.563 1.8 1.8 0 0 1-.853-.904l-.792-1.848A1.5 1.5 0 0 0 11.18 3z"/>
                        </svg>
                    ) : (
                        <>
                            <p className="card-text">Vacant</p>
                            {props.reservation && (
                                <p className="card-text">Next Reservation: {props.reservation}</p>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Modal for confirming spot release */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Release Spot</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to release the spot <strong>{props.spot}</strong>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleConfirmRelease} 
                        disabled={isReleasing}
                    >
                        {isReleasing ? 'Releasing...' : 'Confirm'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ReleaseSpotCard;
