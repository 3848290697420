import React, { useEffect, useState } from 'react';
import { fetchGet, fetchPost } from '../request';
import { SideBar } from "../SideBar";
import './report.css';

export function UpdateParkingLotTime() {
    const [parkingLotId, setParkingLotId] = useState(1); // Assume parking lot ID is known
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [userInfo, setUserInfo] = useState({});
    const [message, setMessage] = useState("Please contact the side admin some changes need to be peroformed on the backend server as well");

    // Fetch user information and parking lot times on component mount
    // useEffect(() => {
    //     fetchGet('/api/account/profile', {}, (data) => {
    //         setUserInfo(data);
    //     });

    //     fetchGet(`/api/reservation/get_lot_time_int/`, {}, (data) => {
    //         if (data.lot_startTimeInt !== undefined && data.lot_endTimeInt !== undefined) {
    //             setStartTime(data.lot_startTimeInt);
    //             setEndTime(data.lot_endTimeInt);
    //         }
    //     });
    // }, [parkingLotId]);

    useEffect(() => {
        fetchGet('/api/reservation/get_lot_time_int', { id: parkingLotId }, (data) => {
            if (data.lot_startTimeInt !== undefined && data.lot_endTimeInt !== undefined) {
                setStartTime(data.lot_startTimeInt);
                setEndTime(data.lot_endTimeInt);
            }
        });
    }, [parkingLotId]);
    

    const handleUpdate = () => {
        setError(""); // Clear previous errors
        setSuccess(""); // Clear previous success message

        if (startTime === null || endTime === null) {
            setError("Both start time and end time must be selected.");
            return;
        }

        if (startTime >= endTime) {
            setError("End time must be greater than start time.");
            return;
        }

        fetchPost('/api/reservation/update_lot_time_int', {
            lot_id: parkingLotId,
            lot_startTimeInt: startTime,
            lot_endTimeInt: endTime,
        }, (data) => {
            if (data.success) {
                setSuccess(data.success);
            } else if (data.error) {
                setError(data.error);
            }
        });
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <SideBar username={userInfo.username} />
                </div>
                <div className="col-md-8">
                    <div className="mt-4 pt-3 border-bottom d-flex justify-content-between align-items-center">
                        <div>
                            <h2>Update Parking Lot Times</h2>
                            <p>Set the start and end times for the selected parking lot.</p>
                        </div>
                    </div>

                    {error && <div className="alert alert-danger mt-4">{error}</div>}
                    {success && <div className="alert alert-success mt-4">{success}</div>}
                    {success && <div className="alert alert-success mt-4">{message}</div>}

                    <div className="form-group mt-4">
                        <label htmlFor="startTime">Start Time:</label>
                        <select
                            id="startTime"
                            className="form-control"
                            value={startTime || ""}
                            onChange={(e) => setStartTime(parseInt(e.target.value))}
                        >
                            <option value="" disabled>Select Start Time</option>
                            {[...Array(24).keys()].map(hour => (
                                <option key={hour} value={hour}>{hour}:00</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="endTime">End Time:</label>
                        <select
                            id="endTime"
                            className="form-control"
                            value={endTime || ""}
                            onChange={(e) => setEndTime(parseInt(e.target.value))}
                        >
                            <option value="" disabled>Select End Time</option>
                            {[...Array(24).keys()].map(hour => (
                                <option key={hour} value={hour}>{hour}:00</option>
                            ))}
                        </select>
                    </div>

                    <button className="btn btn-primary mt-4" onClick={handleUpdate}>Update Times</button>
                </div>
            </div>
        </div>
    );
}

export default UpdateParkingLotTime;
