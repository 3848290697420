import React, { useEffect, useState } from 'react';
import { SideBar } from '../SideBar';
import { fetchGet } from '../request';
import { ReportCard } from './ReportCard';
import { Container, Row, Col } from 'react-bootstrap';
import '../HomePage/history.css';

const formatNum = (num) => (num < 10 ? '0' + num : num);

export function ReportHistory() {
    const [didUpdate, setDidUpdate] = useState(false);
    const [reportHistory, setReportHistory] = useState([]);
    const [hasReportHistory, setHasReportHistory] = useState(false);

    const updateListCallback = (data) => {
        if (data.success === "true") {
            const reports = data.reports.map((report, index) => {
                const time = new Date(report.time);
                return {
                    id: index,  // Assuming there is no 'id' field in the response
                    date: `${time.getMonth() + 1}/${time.getDate()}/${time.getFullYear()}`,
                    time: `${formatNum(time.getHours())}:${formatNum(time.getMinutes())}`,
                    parkingLot: report.parkingLot,
                    spot: report.spot,
                    username: report.username,
                    email: report.email,
                    tel: report.tel,
                    plate: report.plateNumber,
                    report_status: report.report_status,
                    pic: report.pic
                };
            });

            setReportHistory(reports);
            setHasReportHistory(reports.length > 0);
            setDidUpdate(true);
        }
    };

    useEffect(() => {
        if (!didUpdate) {
            fetchGet('/api/report/user_reports/', {}, updateListCallback);
        }
    }, [didUpdate]);

    return (
        <Container fluid>
            <Row>
                <Col md={3}>
                    <SideBar username={localStorage.getItem('username')} />
                </Col>
                <Col md={8} className="p-4">
                    {!hasReportHistory ? (
                        <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 56px)' }}>
                            <div className="text-center">
                                <h3 style={{ fontWeight: 'bold' }}>No Report History</h3>
                            </div>
                        </div>
                    ) : (
                        <div className="history-trans-container">
                            {reportHistory.map((item, index) => (
                                <ReportCard
                                    key={'report' + index}
                                    id={item.id}
                                    date={item.date}
                                    time={item.time}
                                    parkingLot={item.parkingLot}
                                    spot={item.spot}
                                    username={item.username}
                                    email={item.email}
                                    tel={item.tel}
                                    plate={item.plate}
                                    report_status={item.report_status}
                                    pic={item.pic}
                                />
                            ))}
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default ReportHistory;
