import React from 'react';
import { Route, Redirect } from 'react-router-dom';
/**
 * Set up a restricted React Routes for manager to prevent
 * unauthorized user from accessing the page from direct url
 * @param Component
 * @param rest - Rest of the parameters
 * @returns {JSX.Element} - Return customized router element
 * @constructor - Default
 */
const ManagerRoutes = ({ component: Component, ...rest }) => {
    const userRole = localStorage.getItem('userRole'); // Assuming you store the user's role in localStorage
  return (
    <Route
      {...rest}
      render={props =>
        userRole === '1'  ? 
          (<Component {...props} />):<Redirect to="/" /> // Redirect to home or any other page if not authorized
      }
    />
  );
};
/**
 * Set up a restricted React Routes for customer to prevent
 * unauthorized user from accessing the page from direct url
 * @param Component
 * @param rest - Rest of the parameters
 * @returns {JSX.Element} - Return customized router element
 * @constructor - Default
 */
const CustomerRoutes = ({ component: Component, ...rest }) => {
  let auth_token = localStorage.getItem('userRole')
  return (
      <Route {...rest} render={props => (auth_token === "0" || auth_token === "1" ?
          (<Component  {...props}/>) : (<Redirect to='/'/>))}/>
  )
};

export { ManagerRoutes, CustomerRoutes};

