import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SideBar } from "./SideBar";
import { fetchGet, fetchPost } from "./request";
import { Modal, Button, Form } from 'react-bootstrap';

import "../App.css";
//tmpcmt
function ProfilePage1(props) {
    const [tel, setTel] = useState('');
    const [plate, setPlate] = useState('');
    const [balance, setBalance] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); // Added state for password
    const [username, setUsername] = useState('');
    const [didUpdate, setDidUpdate] = useState(false);
    const [modify, setModify] = useState(false);
    const [showModal, setShowModal] = useState(false);
    // const [showPhoneModal, setShowPhoneModal] = useState(false);
    const [showEmailModal, setshowEmailModal] = useState(false);
    const [showPlateModal, setShowPlateModal] = useState(false);    

    const [coupon, setCoupon] = useState('');
    const [showPasswordModal, setShowPasswordModal] = useState(false); // Added state for password modal

    // const handleShowPhoneModal = () => setShowPhoneModal(true);
    // const handleClosePhoneModal = () => setShowPhoneModal(false);

    const handelShowEmailModal = () => setshowEmailModal(true);
    const handelCloseEmailModal = () => setshowEmailModal(false);

    const handleShowPlateModal = () => setShowPlateModal(true);
    const handleClosePlateModal = () => setShowPlateModal(false);
    const handleShowPasswordModal = () => setShowPasswordModal(true); // Function to show password modal
    const handleClosePasswordModal = () => setShowPasswordModal(false); // Function to close password modal
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showNotification, setShowNotification] = useState(false);


    const history = useHistory();

    const formatNumber = (input) => {
        const digits = input.replace(/\D/g, '');
        const match = digits.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            const part1 = match[1] ? `(${match[1]}` : '';
            const part2 = match[2] ? `) ${match[2]}` : '';
            const part3 = match[3] ? `-${match[3]}` : '';
            return `${part1}${part2}${part3}`;
        }
        return '';
    };

    const handleModify = (event) => {
        event.preventDefault();
        setShowModal(true);
    };
    
    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    const updateCallback = (data) => {
        setTel(formatNumber(data.tel));
        setPlate(data.plate);
        setBalance("$ " + Number(data.balance).toFixed(2));
        setEmail(data.email);
        setUsername(data.username);
        // Set notification for plate change
    };
    const plateupdateCallback = (data) => {
        setPlate(data.plate);
        // Set notification for plate change
        setNotificationMessage('Plate number updated successfully.');
        setShowNotification(true);
    };
    const updateCallbackEmail = (data) => {
        // setTel(formatNumber(data.tel));
        // setPlate(data.plate);
        // setBalance("$ " + Number(data.balance).toFixed(2));
        // setEmail(data.email);
        // setUsername(data.username);
    };
    const updateCallbackPassword = (data) => {
        // Handle password update callback if needed
        // Set notification for password change
        setNotificationMessage('Password updated successfully.');
        setShowNotification(true);
    };
    const handlePasswordSubmit = (event) => {
        event.preventDefault();
        const params = {
            password: password
        };
        fetchPost('/api/account/modify', params, updateCallbackPassword); // Same endpoint, different parameter
        setShowPasswordModal(false);
    };
    const handleEmailSubmit = (event) => {
        event.preventDefault();
        const params = {
            email: email
        };
        fetchPost('/api/account/modifyemail', params, updateCallbackEmail);
        handelCloseEmailModal(); // Assuming you have a modal for updating the email
    };
    
    const handlePlateSubmit = (event) => {
        event.preventDefault();
        const params = {
            plate: plate
        };
        fetchPost('/api/account/modify', params, plateupdateCallback);
        setShowPlateModal(false);
    };
    
    const handleRedeem = (event) => {
        event.preventDefault();
        const params = {
            "username": localStorage.getItem("username"),
            "promotionCode": coupon
        };
    
        fetchPost('/api/payment/redeem', params, redeemCallback);
    };
    
    const redeemCallback = (data) => {
        if ('balance' in data) {
            setBalance(data['balance']);
            alert("Successfully redeemed the coupon.");
        } else {
            alert(data['error']);
        }
    };
    
    

    useEffect(() => {
        console.log(showNotification)
        if (didUpdate) {
            return;
        }
        setDidUpdate(true);
        fetchGet('/api/account/profile', {}, updateCallback);
    
        // If a notification is shown, set a timeout to hide it after a few seconds
        if (showNotification) {
            const timer = setTimeout(() => {
                setShowNotification(false);
                setNotificationMessage("");
                console.log("hey")
            }, 3000); // Hide after 3 seconds
    
            // Clean up the timer when the effect is re-run or component is unmounted
            return () => clearTimeout(timer);
        }
    }, [didUpdate, showNotification]);
    

    return (
        <div className="profile-page container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <SideBar username={username} />
                </div>
                <div className="col-md-8">
                    <div className="profile-box user-info mb-4">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <div className="d-flex align-items-center">
                                <div className="profile-picture">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                        <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                                    </svg>
                                    &nbsp;
                                    &nbsp;
                                    &nbsp;
                                </div>
                                <div className="profile-info ml-3">
                                    <h2>{username}</h2>
                                    <p>{email}</p>
                                    <p>{tel}</p>
                                </div>
                            </div>
                            <span className="clickable" onClick={handelShowEmailModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square mr-1" viewBox="0 0 16 16">
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                            </svg>
                            &nbsp;
                            Edit
                        </span>
                        </div>
                    </div>
                    <div className="profile-box payment-method mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Payment Method</h5>
                            {/* <span className="clickable">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-plus-circle mr-1" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                                </svg>
                                &nbsp;
                                Add Card
                            </span> */}
                        </div>
                        <div className="payment-details mt-4">
                            {/* <p className="d-flex align-items-center">
                                <strong>My Card 1:</strong>
                                &nbsp;
                                &nbsp;
                                &nbsp;
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-credit-card mr-1" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1z"/>
                                    <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1z"/>
                                </svg>
                                &nbsp;
                                VISA ************1234 
                                <span className="clickable d-flex align-items-center ml-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square mr-1" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                    </svg>
                                    &nbsp;
                                    Edit
                                </span>
                            </p> */}
                            <p><strong>Balance: </strong>&nbsp;&nbsp;{balance}</p>
                            <div className="coupon-section">
                                <p><strong>Coupon:</strong></p>
                                <input 
                                    type="text" 
                                    placeholder="Coupon" 
                                    className="form-control d-inline w-auto mr-2" 
                                    value={coupon}
                                    onChange={(e) => setCoupon(e.target.value)} 
                                />
                                <span className="clickable" onClick={handleRedeem}>Redeem</span>
                            </div>
                        </div>
                    </div>
                    <div className="profile-box vehicle-info">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>My Vehicle</h5>
                            
                        </div>
                        <div className="vehicle-details mt-4">
                            <p className="d-flex align-items-center">
                                <strong>My Car 1: </strong> &nbsp;&nbsp;&nbsp;{plate} 
                                <span className="clickable d-flex align-items-center ml-3" onClick={handleShowPlateModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square mr-1" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                    </svg>
                                    &nbsp;
                                    Edit Plate Number
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="profile-box vehicle-info">
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Password Reset</h5>
                        </div>  
                        <div className="vehicle-details mt-4">
                            <p className="d-flex align-items-center">
                                <strong>Password: </strong> &nbsp;&nbsp;&nbsp;********
                                <span className="clickable d-flex align-items-center ml-3" onClick={handleShowPasswordModal}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square mr-1" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                        <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                                    </svg>
                                    &nbsp;
                                    Edit Password
                                </span>
                            </p>
                        </div>
                    </div>
                    {showNotification && (
                    <div className="alert alert-success" role="alert">
                        {notificationMessage}
                    </div>
                    )}
                </div>
                {/* Password Reset Modal */}
                <Modal show={showPasswordModal} onHide={handleClosePasswordModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handlePasswordSubmit}>
                            <Form.Group controlId="formPassword">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control 
                                    type="password" 
                                    placeholder="Enter new password" 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} 
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={showEmailModal} onHide={handelCloseEmailModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Email Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(event) => handleEmailSubmit(event, email)}>
                            <Form.Group controlId="formEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control 
                                    type="email" 
                                    placeholder="Enter new email address" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                <Modal show={showPlateModal} onHide={handleClosePlateModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Plate Number</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handlePlateSubmit}>
                            <Form.Group controlId="formPlate">
                                <Form.Label>Plate Number</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Enter plate number" 
                                    value={plate} 
                                    onChange={(e) => setPlate(e.target.value)} 
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Save Changes
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    );
}

export default ProfilePage1;
