import React, { useEffect, useState } from 'react';
import { SideBar } from "../SideBar";
import ReleaseSpotCard from './releaseSpotCard'; // Retain SpotCard
import './report.css';
import { fetchGet, uploadData } from '../request';

export function ReleaseSpots(props) {
    const [parkingLot, setParkingLot] = useState("Morewood test 1");
    const [spots, setSpots] = useState([]);
    const [didUpdate, setDidUpdate] = useState(false);
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        if (!didUpdate) {
            fetchGet('/api/account/profile', {}, updateCallback);
        }
    }, [didUpdate]);

    const updateCallback = (data) => {
        setUserInfo(data);
        setDidUpdate(true);
    };

    const fetchSpots = () => {
        fetchGet('/api/report/spots', { "parkingLot": parkingLot }, fetchSpotsCallback);
    };

    const fetchSpotsCallback = (data) => {
        if ("success" in data) {
            setSpots(data["spots"]);
        }
    };

    useEffect(() => {
        if (!didUpdate) {
            fetchSpots();
            setInterval(fetchSpots, 3000000); // 50 minutes
            setDidUpdate(true);
        }
    }, [didUpdate]);

    const refreshSpots = () => {
        fetchSpots(); // Re-fetch the spots after a spot is released
    };

    const rows = [];
    const reservedSpots = spots.filter(spot => spot.plate === "RESERVED");

    for (let i = 0; i < reservedSpots.length; i += 6) {
        rows.push(reservedSpots.slice(i, i + 6));
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <SideBar username={userInfo.username} />
                </div>
                <div className="col-md-8">
                    <div className="mt-4 pt-3 mr-4 pr-4 border-bottom d-flex justify-content-between align-items-center">
                        <div>
                            <h4>Release Reserved Spot</h4>
                            <p>Manage reserved spots that are no longer in use</p>
                        </div>
                    </div>
                    <div className="d-flex mt-4 mb-4 align-items-center">
                        <span>Select Parking Lot:</span>
                        &nbsp;&nbsp;&nbsp;
                        <select className="form-control form-control-md" onChange={(e) => { setParkingLot(e.target.value); fetchSpots(); }}>
                            <option>Morewood test 1</option>
                        </select>
                    </div>
                    <div className="spot-container">
                        {rows.map((row, rowIndex) => (
                            <div key={rowIndex} className="spot-row">
                                {row.map((item, index) => (
                                    <div key={index} className="spot-col">
                                        <ReleaseSpotCard 
                                            parkingLot={parkingLot} 
                                            spot={item.name} 
                                            plate={item.plate} 
                                            userInfo={userInfo} 
                                            reservation={item.reservation} 
                                            uploadData={uploadData} 
                                            refreshSpots={refreshSpots}  // Pass the refresh function
                                        />
                                    </div>
                                ))}
                                {row.length < 6 && [...Array(6 - row.length)].map((_, index) => (
                                    <div key={`empty-${index}`} className="spot-col"></div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReleaseSpots;
