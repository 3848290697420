import React, { useEffect, useState } from 'react';
import { SideBar } from '../SideBar';
import { NavBar } from '../NavBar';
import { fetchGet } from '../request';
import { HistoryCard } from './HistoryCard';
import { Container, Row, Col } from 'react-bootstrap';
import './history.css';

const checkInAdvance = 15 * 60 * 1000; // 15 minutes in milliseconds

const formatNum = (num) => (num < 10 ? '0' + num : num);

export function HistoryHome() {
    const [didUpdate, setDidUpdate] = useState(false);
    const [pastTrans, setPastTrans] = useState([]);
    const [hasPastTrans, setHasPastTrans] = useState(false);

    const updateListCallback = (data) => {
        console.log(data)
        let pastTrans = [];
        for (let i = 0; i < data.length; i++) {
            let record = {};
            let checkInTime = new Date(data[i]['checkInTime']);
            let scheduledLeaveTime = new Date(data[i]['scheduledLeaveTime']);
            let checkOutTime = null;
            if (data[i]['isCompleted'] === true) {
                checkOutTime = new Date(data[i]['checkOutTime']);
            }
            record['id'] = data[i]['id'];
            record['date'] = checkInTime.getMonth() + 1 + "/" + checkInTime.getDate() + "/" + checkInTime.getFullYear();
            record['startTime'] = formatNum(checkInTime.getHours()) + ":" + formatNum(checkInTime.getMinutes());
            if (checkOutTime != null) {
                record['endTime'] = formatNum(checkOutTime.getHours()) + ":" + formatNum(checkOutTime.getMinutes());
            } else {
                record['endTime'] = formatNum(scheduledLeaveTime.getHours()) + ":" + formatNum(scheduledLeaveTime.getMinutes());
            }
            record['parkingLot'] = data[i]['parkingLot'];
            record['spot'] = data[i]['spot'];
            record['checkInTime'] = checkInTime;
            record['scheduledLeaveTime'] = scheduledLeaveTime;
            record['checkOutTime'] = checkOutTime;
            record['charge'] = data[i]['charge'];
            record['reservationFee'] = data[i]['reservationFee'];
            record['isCompleted'] = data[i]['isCompleted'];
            record['isCanceled'] = data[i]['isCanceled'];
            record['price'] = (parseFloat(data[i]['charge']) + parseFloat(data[i]['reservationFee'])).toFixed(2);
            record['refund'] = data[i]['refund']

            if (data[i]['isCompleted'] === true || data[i]['isCanceled'] === true) {
                pastTrans.push(record);
            }
        }
        setPastTrans(pastTrans);
        setHasPastTrans(pastTrans.length > 0);
        setDidUpdate(true);
    };

    const fetchData = () => {
        fetchGet('/api/reservation/', {}, updateListCallback);
    };

    useEffect(() => {
        fetchData();
        const intervalId = setInterval(fetchData, 60000); // Fetch data every 60 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, []);

    return (
        <Container fluid>
            <Row>
                <Col md={3}>
                    <SideBar username={localStorage.getItem('username')} />
                </Col>
                <Col md={8} className="p-3">
                    <NavBar />
                    {!hasPastTrans ? (
                        <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 56px)' }}>
                            <div className="text-center">
                                <h3 style={{ fontWeight: 'bold' }}>No Parking History</h3>
                            </div>
                        </div>
                    ) : (
                        <div className="history-trans-container">
                            {pastTrans.map((item, index) => (
                                <HistoryCard
                                    key={'past' + index}
                                    id={item['id']}
                                    startTime={item['startTime']}
                                    endTime={item['endTime']}
                                    date={item['date']}
                                    parkingLot={item['parkingLot']}
                                    spot={item['spot']}
                                    price={item['price']}
                                    isCanceled={item['isCanceled']}
                                    isCompleted={item['isCompleted']}
                                    reservationFee={item['reservationFee']}
                                    charge={item['charge']}
                                    checkInTime={item['checkInTime']}
                                    scheduledLeaveTime={item['scheduledLeaveTime']}
                                    checkOutTime={item['checkOutTime']}
                                    updateHandle={() => setDidUpdate(false)}
                                    refund={item['refund']}
                                />
                            ))}
                        </div>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default HistoryHome;

// import React, { useEffect, useState } from 'react';
// import { SideBar } from '../SideBar';
// import { NavBar } from '../NavBar';
// import { fetchGet } from '../request';
// import { HistoryCard } from './HistoryCard';
// import './history.css';

// const checkInAdvance = 15 * 60 * 1000; // 15 minutes in milliseconds

// const formatNum = (num) => (num < 10 ? '0' + num : num);

// export function HistoryHome() {
//     const [didUpdate, setDidUpdate] = useState(false);
//     const [pastTrans, setPastTrans] = useState([]);
//     const [hasPastTrans, setHasPastTrans] = useState(false);

//     const updateListCallback = (data) => {
//         let pastTrans = [];
//         for (let i = 0; i < data.length; i++) {
//             let record = {};
//             let checkInTime = new Date(data[i]['checkInTime']);
//             let scheduledLeaveTime = new Date(data[i]['scheduledLeaveTime']);
//             let checkOutTime = null;
//             if (data[i]['isCompleted'] === true) {
//                 checkOutTime = new Date(data[i]['checkOutTime']);
//             }
//             record['id'] = data[i]['id'];
//             record['date'] = checkInTime.getMonth() + 1 + "/" + checkInTime.getDate() + "/" + checkInTime.getFullYear();
//             record['startTime'] = formatNum(checkInTime.getHours()) + ":" + formatNum(checkInTime.getMinutes());
//             if (checkOutTime != null) {
//                 record['endTime'] = formatNum(checkOutTime.getHours()) + ":" + formatNum(checkOutTime.getMinutes());
//             } else {
//                 record['endTime'] = formatNum(scheduledLeaveTime.getHours()) + ":" + formatNum(scheduledLeaveTime.getMinutes());
//             }
//             record['parkingLot'] = data[i]['parkingLot'];
//             record['spot'] = data[i]['spot'];
//             record['checkInTime'] = checkInTime;
//             record['scheduledLeaveTime'] = scheduledLeaveTime;
//             record['checkOutTime'] = checkOutTime;
//             record['charge'] = data[i]['charge'];
//             record['reservationFee'] = data[i]['reservationFee'];
//             record['isCompleted'] = data[i]['isCompleted'];
//             record['isCanceled'] = data[i]['isCanceled'];
//             record['price'] = (parseFloat(data[i]['charge']) + parseFloat(data[i]['reservationFee'])).toFixed(2);

//             if (data[i]['isCompleted'] === true || data[i]['isCanceled'] === true) {
//                 pastTrans.push(record);
//             }
//         }
//         setPastTrans(pastTrans);
//         setHasPastTrans(pastTrans.length > 0);
//         setDidUpdate(true);
//     };

//     const fetchData = () => {
//         fetchGet('/api/reservation/', {}, updateListCallback);
//     };

//     useEffect(() => {
//         fetchData();
//         const intervalId = setInterval(fetchData, 60000); // Fetch data every 60 seconds

//         return () => clearInterval(intervalId); // Cleanup interval on component unmount
//     }, []);

//     return (
//         <div className='container-fluid'>
//             <div className='row g-0'>
//                 <div className="col-md-3">
//                     <SideBar username={localStorage.getItem('username')} />
//                 </div>
//                 <div className="col-md-9 p-3">
//                     <NavBar />
//                     {!hasPastTrans ? (
//                         <div className="d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 56px)' }}>
//                             <div className="text-center">
//                                 <h3 style={{ fontWeight: 'bold' }}>No Parking History</h3>
//                             </div>
//                         </div>
//                     ) : (
//                         <div className="history-trans-container">
//                             {pastTrans.map((item, index) => (
//                                 <HistoryCard
//                                     key={'past' + index}
//                                     id={item['id']}
//                                     startTime={item['startTime']}
//                                     endTime={item['endTime']}
//                                     date={item['date']}
//                                     parkingLot={item['parkingLot']}
//                                     spot={item['spot']}
//                                     price={item['price']}
//                                     isCanceled={item['isCanceled']}
//                                     isCompleted={item['isCompleted']}
//                                     reservationFee={item['reservationFee']}
//                                     charge={item['charge']}
//                                     checkInTime={item['checkInTime']}
//                                     scheduledLeaveTime={item['scheduledLeaveTime']}
//                                     checkOutTime={item['checkOutTime']}
//                                     updateHandle={() => setDidUpdate(false)}
//                                 />
//                             ))}
//                         </div>
//                     )}
//                 </div>
//             </div>
//         </div>
//     );
// }

// export default HistoryHome;