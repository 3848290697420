import React, { useState } from 'react';

export function ReportForm({ show, handleClose, parkingLot, spot, userInfo, uploadData }) {
    const [picture, setPicture] = useState(null);
    const [picPrompt, setPicPrompt] = useState("Attach a picture to report");
    const [plate, setPlate] = useState('');
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState(''); // 'success' or 'error'
    const [loading, setLoading] = useState(false); // Added loading state

    const handleFileUpload = (event) => {
        let file = event.target.files[0];
        if (file) {
            setPicture(file);
            setPicPrompt(file.name);
        }
    };

    const handleReport = (event) => {
        event.preventDefault();
        setLoading(true); // Set loading to true when the form is submitted

        const data = new FormData();
        const userEmail = localStorage.getItem('userEmail');

        data.append("parkingLot", parkingLot);
        data.append("spot", spot);
        data.append("time", Date.now());
        data.append("pic", picture);
        data.append("username", userInfo.username);
        data.append("email", userEmail);
        data.append("tel", userInfo.tel);
        data.append("plate", plate);
        data.append("report_status", "");

        uploadData('/api/report', data, (response) => {

            if ('error' in response) {
                setMessageType('error');
                setMessage('Failed to submit the report.');
            } else {
                setMessageType('success');
                if (response.reservation_exists) {
                    setMessage(response.internal_api_result.message);
                } else {
                    setMessage('Report submitted successfully. Upon review will receive reward');
                }
            }

            // Close the modal after displaying the message for 5 seconds
            setTimeout(() => {
                setLoading(false); // Set loading to false when the response is received
                setMessage('');
                handleClose();
                resetForm(); // Clear the form once the response is handled
            }, 5000);
        });
    };

    // Reset form fields and states
    const resetForm = () => {
        setPicture(null);
        setPicPrompt("Attach a picture to report");
        setPlate('');
        setMessage('');
        setMessageType('');
    };

    return (
        <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-custom" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Report Violation</h5>
                        <button type="button" className="close" onClick={handleClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {message && (
                            <div className={`alert alert-${messageType}`} role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleReport}>
                            <div className="form-group">
                                <label htmlFor="formGridSpotNumber">Spot Number</label>
                                <input type="text" className="form-control" id="formGridSpotNumber" value={spot} readOnly />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGridPlate">Violator's Car Plate</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="formGridPlate"
                                    placeholder="Enter violator's plate"
                                    value={plate}
                                    onChange={(e) => setPlate(e.target.value)}
                                    disabled={loading} // Disable input while loading
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formFileUpload">Upload Picture Proof</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="formFileUpload"
                                    onChange={handleFileUpload}
                                    disabled={loading} // Disable file upload while loading
                                />
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-custom btn-cancel"
                                    onClick={handleClose}
                                    disabled={loading} // Disable cancel button while loading
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-custom btn-confirm"
                                    disabled={!picture || !plate || loading} // Disable confirm button if no picture, plate, or while loading
                                >
                                    {loading ? 'Submitting...' : 'Confirm'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
