import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { SideBar } from './SideBar';
import { fetchPost } from './request';

export function GenerateCouponPage() {
    const [phone, setPhone] = useState('');
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const generateCouponCallback = (data) => {
        if ('error' in data) {
            setSeverity("warning");
            setMessage(data['error']);
        } else if ('msg' in data) {
            setSeverity("success");
            setMessage(data['msg']);
        }
        setOpen(true);
        setLoading(false);
        
        // Clear the input fields after the API call is completed
        setPhone('');
        setValue('');
        // Automatically close the alert after 5 seconds
        setTimeout(() => {
            setOpen(false);
        }, 5000);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        setLoading(true);

        const couponParam = {
            'value': value,
            'phone': phone
        };

        fetchPost("/api/payment/generate_coupon", couponParam, generateCouponCallback);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container fluid>
            <Row>
                <Col md={3}>
                    <SideBar username={localStorage.getItem("username")} />
                </Col>
                <Col md={8} className="p-3">
                    <h1 className="text-center">Generate Coupons</h1>
                    <Container className="d-flex justify-content-center">
                        <Form onSubmit={handleFormSubmit} className="w-50">
                            <Form.Group controlId="formPhoneNumber">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter phone number"
                                    value={phone}
                                    onChange={e => setPhone(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="formCouponValue" className="mt-3">
                                <Form.Label>Coupon Value</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter coupon value"
                                    value={value}
                                    onChange={e => setValue(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Button 
                                variant="primary" 
                                type="submit" 
                                className="mt-4" 
                                block={true}
                                disabled={loading}
                            >
                                {loading ? 'Generating...' : 'Generate'}
                            </Button>
                        </Form>
                    </Container>
                    {open && (
                        <Container className="d-flex justify-content-center mt-4">
                            <Alert 
                                variant={severity} 
                                onClose={handleClose} 
                                dismissible
                                style={{ wordBreak: 'break-word', maxWidth: '400px', maxHeight: '100px', textAlign: 'center' }}  // Added text alignment for better visual appearance
                            >
                                {message.length > 50 ? `${message.substring(0, 50)}...` : message}  {/* Truncate long messages */}
                            </Alert>
                        </Container>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default GenerateCouponPage;
