import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { 
    TextField, 
    Button, 
    Grid, 
    Typography, 
    Container, 
    Link,
    Checkbox,
    FormControlLabel,
    Modal,
    Box,
    Alert
  } from '@mui/material';
// Import the environment variable
const isProd = process.env.REACT_APP_PROD === 'true';

// Set the host based on the environment
const httpval = isProd ? 'https://' : 'http://';
export function SignUp1(props) {
    const [username, setUsername] = useState('');
    const [pwd, setPwd] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [plate, setPlate] = useState(''); 
    const [confirmPwd, setConfirmPwd] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [uppercaseError, setUppercaseError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAgreedAge, setisAgreedAge] = useState(false);
    const [isAgreedCmuMemeber, setisAgreedCmuMemeber] = useState(false);
    const [isAgreedInformation, setisAgreedInformation] = useState(false);
    const [isAgreedParticipation, setisAgreedParticipation] = useState(false);
    const [isNotificationVisible, setIsNotificationVisible] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const handleisAgreedAgeChange = (event) => setisAgreedAge(event.target.checked);
    const handleisAgreedCmuMemeberChange = (event) => setisAgreedCmuMemeber(event.target.checked);
    const handleisAgreedInformationChange = (event) => setisAgreedInformation(event.target.checked);
    const handleisAgreedParticipationChange = (event) => setisAgreedParticipation(event.target.checked);
    const handleContinue = () => {
        if (isAgreedAge && isAgreedCmuMemeber && isAgreedInformation && isAgreedParticipation) {
            handleActualSubmit();
        }
    };

    const history = useHistory();
    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (!validatePassword(pwd) || !validateConfirmPassword(pwd, confirmPwd)) {
            return;
        }
    
        handleOpenModal();
    };
    const handleActualSubmit = () => {
        const data = {
            username: username,
            password: pwd,
            email: email,
            tel: stripNonNumeric(tel),
            plate: plate,
        };
    
        fetch(httpval + localStorage.getItem('host') + '/api/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((err) => {
                        throw new Error(JSON.stringify(err));
                    });
                }
                return response.json();
            })
            .then((data) => {
                localStorage.setItem('userEmail', email);
                localStorage.setItem('userPhone', tel);
                setNotificationMessage('Successfully registered! Please sign in.');
                setIsNotificationVisible(true);
                setTimeout(() => {
                    setIsNotificationVisible(false);
                    history.push('/signin');
                }, 3000);
            })
            .catch((error) => {
                try {
                    const errorMsg = JSON.parse(error.message);
                    console.log(errorMsg)
                    if (errorMsg.username) {
                        setUsernameError('Username already exists.');
                    }
                    else{
                        setUsernameError('');
                    }
                    if (errorMsg.phoneError) {
                        setPhoneError(errorMsg.phoneError)
                    }
                    else{
                        setPhoneError('')
                    }
                    if (errorMsg.emailError) {
                        setEmailError(errorMsg.emailError)
                    }
                    else{
                        setEmailError('')
                    }
                } catch (parseError) {
                    console.error('Failed to parse error message as JSON:', parseError);
                    setUsernameError('An unexpected error occurred.');
                }
            });
    
        handleCloseModal();
    };
    

    const handlePassword = (event) => {
        const newPassword = event.target.value;
        setPwd(newPassword);
        validatePassword(newPassword);
    };
    const handleEmail = (event) => {
        setEmail(event.target.value);
    };

    const validatePassword = (password) => {
        let valid = true;
        setPasswordError('');
        setUppercaseError('');

        if (password.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
            valid = false;
        }
        if (!password.match(/[A-Z]/)) {
            setUppercaseError('Password must contain at least one uppercase letter.');
            valid = false;
        }

        return valid;
    };

    const handleConfirm = (event) => {
        const newConfirmPwd = event.target.value;
        setConfirmPwd(newConfirmPwd);
        validateConfirmPassword(pwd, newConfirmPwd);
    };

    const validateConfirmPassword = (password, confirmPassword) => {
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords do not match.');
            return false;
        }
        setConfirmPasswordError('');
        return true;
    };

    const handlePhoneNumber = (event) => {
        const formatted = formatNumber(event.target.value);
        setTel(formatted);
    };

    const formatNumber = (input) => {
        const digits = input.replace(/\D/g, '');
        const match = digits.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

        if (match) {
            const part1 = match[1] ? `(${match[1]}` : '';
            const part2 = match[2] ? `) ${match[2]}` : '';
            const part3 = match[3] ? `-${match[3]}` : '';
            return `${part1}${part2}${part3}`;
        }
        return '';
    };

    const stripNonNumeric = (input) => {
        return input.replace(/\D/g, '');
    };

    const handleSignIn = (event) => {
        event.preventDefault();
        history.push('/signin');
    };
    return (
        <Container 
            component="main" 
            maxWidth={false} 
            sx={{ 
                height: '100vh', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                padding: '0'
            }}
        >
            <Grid 
                container 
                spacing={3} 
                sx={{ 
                    flex: 1, 
                    height: '100%' 
                }}
            >
                <Grid 
                    item 
                    xs={12} 
                    md={5}  
                    sx={{ 
                        backgroundColor: '#C41230', 
                        display: 'flex', 
                        flexDirection: 'column', 
                        justifyContent: 'center' ,
                        padding: '1rem'
                    }}
                >
                    <Typography variant="h4" align="center" sx={{ color: 'white', fontWeight: 'bold' , marginBottom: '1rem' }}>
                        Reserve Your Parking Space Today! 
                    </Typography>
                    <Typography variant="h5" align="center" sx={{ color: 'white' , fontWeight: 'bold', marginBottom: '4rem'}}> 
                        <Link href={'https://parking-pilot.cmu.edu/faq'} sx={{ color: 'white', textDecoration: 'underline', marginLeft: '4px' }}>
                            FAQ                        
                        </Link>
                    </Typography>
                    
                    <Typography variant="h6" align="center" sx={{ color: 'white', fontWeight: 'bold' }}>
                        CMU Parking
                    </Typography>
                    <Typography variant="body1" align="center" sx={{ color: 'white' }}>
                        We are a team of CMU researchers who are conducting research on how to improve parking efficiency
                        and reduce parking operational costs. A non-sensor parking pilot project is being developed and
                        tested at CMU’s Morewood Parking Lot. Be one of the first to try out this service and receive
                        parking credit to park for free during phase one of the program. If successful, the service will be later
                        expanded to include a fee to reserve and park.
                    </Typography>
                    <Typography variant="body1" align="center" sx={{ color: 'white' }}>
                        Contact: <Link href="mailto:tartanparking@gmail.com" sx={{ color: 'white', textDecoration: 'underline', marginLeft: '4px' }}>
                            tartanparking@gmail.com
                        </Link>
                        ,   or Sean Qian,
                        <Link href="mailto:seanqian@cmu.edu" sx={{ color: 'white', textDecoration: 'underline', marginLeft: '4px' }}>
                            seanqian@cmu.edu
                        </Link>, 412-268-4155
                    </Typography>
                    
                </Grid>
    
                <Grid 
                    item 
                    xs={12} 
                    md={7} 
                    sx={{ 
                        display: 'flex', 
                        alignItems: 'center', 
                        justifyContent: 'center' 
                    }}
                >
                    <form onSubmit={handleSubmit}>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center" gutterBottom sx={{ color: 'black', fontWeight: 'bold' , marginBottom: '2rem' }}>
                                Let's get you started
                            </Typography>
                        </Grid>
                        <Grid container spacing={2}  sx={{padding: '1rem'}}>
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            Username *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="inputUsername"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            error={!!usernameError}
                                            helperText={usernameError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            Password *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            type="password"
                                            id="inputPassword"
                                            value={pwd}
                                            onChange={handlePassword}
                                            error={!!passwordError || !!uppercaseError}
                                            helperText={passwordError || uppercaseError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            Confirm Password *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            type="password"
                                            id="confirmPassword"
                                            value={confirmPwd}
                                            onChange={handleConfirm}
                                            error={!!confirmPasswordError}
                                            helperText={confirmPasswordError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            Email *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            type="email"
                                            id="inputEmail"
                                            value={email}
                                            onChange={handleEmail}
                                            error={!!emailError}
                                            helperText={emailError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            Phone Number *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="inputPhone"
                                            value={tel}
                                            onChange={handlePhoneNumber}
                                            error={!!phoneError}
                                            helperText={phoneError}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} md={3}>
                                        <Typography variant="body1">
                                            Plate Number *
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            id="inputPlate"
                                            value={plate}
                                            onChange={(e) => setPlate(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    type="submit"
                                    sx={{ backgroundColor: '#C41230', color: 'white', '&:hover': { backgroundColor: '#a01028' } }}
                                >
                                    Sign Up
                                </Button>
                            </Grid>
    
                            <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    align="center"
                                    sx={{ cursor: 'pointer' }}
                                    onClick={handleSignIn}
                                >
                                    Already a user? Sign In
                                </Typography>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </Grid>
    
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="user-agreement-modal"
                aria-describedby="user-agreement-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        maxHeight: '80vh',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        overflowY: 'auto',
                    }}
                >
                    <Typography id="user-agreement-modal" variant="h6" component="h2">
                        User Agreement
                    </Typography>
                    <Typography id="user-agreement-description" sx={{ mt: 2, mb: 2 }}>
                        Please read and accept our user policy to continue.
                    </Typography>
                    <Box sx={{ maxHeight: '50vh', overflowY: 'auto', mb: 2 }}>
                        {/* User agreement text */}
                        {/* <Typography variant="body2" paragraph>
                            {consentTextBlob}
                        </Typography> */}
                           <b>Online Consent for Non-Sensor-Based Parking Management Pilot Research Program for CMU campus</b>
                           <br />
                           <Typography variant="body2" paragraph>This Non-Sensor-Based Parking Management Pilot Research Program for CMU campus is part of a research study conducted by Dr. Sean Qian at Carnegie Mellon University (CMU). 
                           </Typography>
                           <Typography variant="body2" paragraph>A parking research pilot program integrating a dynamic, real-time parking management system is being tested at a few parking spots in the Morewood Parking Lot of the CMU Pittsburgh Campus. A web application is used to make reservations, allocate spaces, and allow users to report violations. The goal is to create a low-cost, convenient, flexible, and efficient parking system.</Typography>
                           <Typography variant="body2" paragraph><b>Purpose</b> Through this pilot, we would like to test the effectiveness of a non-sensor based parking management system, measure system performance, and under user feedbacks, and possible issues in order to improve and potentially expand the service across CMU’s Pittsburgh Campus.
                           </Typography>
                           <Typography variant="body2" paragraph><b>Procedures</b>  
                            Upon registering and signing the consent agreement form, a user can make a reservation to use the service via the web application at (http://3.19.134.196/). The user can check for availability and reserve a space 30 days ahead, and parking for up to 18 hours on a single day. The earlier the request is submitted, the lower the parking rate is likely to be. The parking web application can also be used for users to pay for parking, with or without reservations
                            </Typography>
                            <Typography variant="body2" paragraph>
                            15 minutes prior to the reservation start time, a space ID is assigned based on current and future occupancy. The user is informed via text message, or by checking the web application. Upon arrival, the user checks in via the web application to start the parking session. The user will also receive a notification when the parking session is about to expire with information about extending the reservation, if possible. If the user decides to leave early, there is an option to check out early via the web application. Early check out will result in a partial refund, and the space will become available for other users.
                            </Typography>
                            <Typography variant="body2" paragraph>
                            Users may also park without a reservation if spaces are available. A user can check for available spaces upon arrival, via the web application, and park and pay as above. In this case, parking may be constrained, or not possible, due to prior reservations made after the present time by other users.
                            </Typography>
                            <Typography variant="body2" paragraph>
                            Additionally, violations may be reported if a user finds another vehicle parked in their reserved space, or if a space that appears available on the web application is found to be occupied. The violation should be photographed and reported via the web application. The vehicle in violation will receive a ticket and possibly be towed by a parking enforcement officer. If a user had a reservation, they may park in another space and report the space being used in the web application. If there is no other space available, the user will receive a full refund, as well as parking credit for future use. If the user did not have a reservation, a parking credit will be issued after the violation is confirmed by an enforcement officer. This is to encourage general public to report parking violations. 
                            </Typography>
                            <Typography variant="body2" paragraph>
                            Use data will be collected for each reservation and/or parking session, strictly for research purposes. In addition, any personally identifiable information (name, vehicle information, phone number, email address, payment information) are only used to operate this service, this data will not be used in the project and will be kept strictly confidential, stored in the cloud server only. 
                            </Typography>
                            <Typography variant="body2" paragraph>
                            <b>Participant Requirements </b>
                            Participation in this study is limited to individuals affiliated with CMU as of June 1, 2024, age 18 and older. 
                            </Typography>
                            <Typography variant="body2" paragraph><b>Risks</b>
                            The risks and discomfort associated with participation in this study are no greater than those ordinarily encountered when parking in any parking facilities and/or utilizing other online parking platform
                            </Typography>
                            <Typography variant="body2" paragraph>You are expected to pay for your rides using a personal account, which is set up along with the signup and consent process. Your account will contain personal information and possibly financial information, just like an account to use Uber/Lyft service. Financial information is optional at this point. You will be provided parking vouchers or credit to replenish your account balance. The web application is software engineered to ensure all transactions are secure and encrypted.</Typography>
                            <Typography variant="body2" paragraph>The data are stored in a cloud service (AWS) that only the PI and research assistants can access to and will be stored only in the cloud with password protection. Though our software system is engineered to protect data confidentiality to the best we can, one risk is a breach of confidentiality only if the cloud service running the software is hacked.</Typography>
                            <Typography variant="body2" paragraph>
                                <b>Benefits</b>
                            Participants will enjoy the benefit of easy and convenient parking through reservations in advance. Reservations through this service may result in cost-savings, especially in the case of advanced reservations, as well as additional credit through the reporting of violations. In addition, the knowledge gained by the research team may help improve parking management on campus, and allow the service to expand, if successful.  
                            </Typography>
                            <Typography variant="body2" paragraph> <b>Compensation & Costs</b>There is no compensation for participation in this study. A limited allocation of parking credits will be assigned in order to utilize the service. A parking credit will be issued to your account for you to park in the research pilot area, which covers the standard campus parking fee and additional parking reservation fee. Additional parking credits may be requested after the initial parking credit is used up, provided that the participate uses the parking credit reasonably.</Typography>
                            <Typography variant="body2" paragraph>CMU will not compensate or reimburse you for any additional costs that you may incur from participating in this research or for medical treatment if you become injured or ill while participating in the research.
                            </Typography>
                            <Typography variant="body2" paragraph>
                                <b>Future Use of Information</b>
                                In the future, once we have removed all identifiable information from your data (name, origin location, vehicle registration information, etc.), we may use the data for our future research studies to optimize parking management services at CMU.  The data, however, will be kept strictly within CMU for research purposes. We will never share the data with anyone else other than CMU researchers who are actively working on this study. Sharing of data among CMU researchers will only be done in such a manner that you will not be identified.</Typography>
                            <Typography variant="body2" paragraph>
                                <b>Confidentiality</b>
                            By participating in this research, you understand and agree that Carnegie Mellon may be required to disclose your consent form, data and other personally identifiable information as required by law, regulation, subpoena or court order.  Otherwise, your confidentiality will be maintained in the following manner:
                            </Typography>
                            <Typography variant="body2" paragraph>
                            Your data and consent form will be kept separate. Your consent form will be stored in a secure location on Carnegie Mellon property and will not be disclosed to third parties. By participating, you understand and agree that the data and information gathered during this study may be used by Carnegie Mellon and published.  However, your name, specific address, contact information and other direct personal identifiers will not be mentioned in any such publication or dissemination of the research data and/or results by Carnegie Mellon. Note that per regulation all research data must be kept for a minimum of 3 years.  
                            </Typography>
                            <Typography variant="body2" paragraph>
                                <b>Right to Ask Questions & Contact Information</b>
                            If you have any questions about this study, you should feel free to ask them by contacting the Principal Investigator now at Dr. Sean Qian, Department of Civil and Environmental Engineering, Carnegie Mellon University, 5000 Forbes Ave, Pittsburgh PA 15213, 412-268-4155, seanqian@cmu.edu.  If you have questions later, desire additional information, or wish to withdraw your participation please contact the Principal Investigator by mail, phone or e-mail in accordance with the contact information listed above.
                            </Typography>
                            <Typography variant="body2" paragraph>If you have questions pertaining to your rights as a research participant; or to report concerns to this study, contact the Office of Research integrity and Compliance at Carnegie Mellon University.  Email: irb-review@andrew.cmu.edu . Phone: 412-268-4721.
                            </Typography>
                            <Typography variant="body2" paragraph><b>
                            Voluntary Participation <br></br>
                            I understand that by utilizing this parking management service I am voluntarily participating in this parking management study. I understand that I may decline this service at any time, without giving a reason and without cost. I understand that information from my participation will be utilized as a part of a transportation research project. I understand that my personal information will not be shared. I understand that this study is being conducted by Carnegie Mellon University Mobility Data Analytics Center.

                                </b></Typography>
                    </Box>
                    <Typography variant="body2" paragraph><b>You may print a copy of this consent form for your records. </b>
                    </Typography>
                    <Button
                        href="/consentform.pdf" // Adjust this path to your actual PDF file location
                        variant="outlined"
                        sx={{ mt: 2 }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Download Agreement
                    </Button>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAgreedAge}
                                onChange={handleisAgreedAgeChange}
                                name="agreementCheckbox"
                            />
                        }
                        label="I am age 18 or older."
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAgreedCmuMemeber}
                                onChange={handleisAgreedCmuMemeberChange}
                                name="agreementCheckbox"
                            />
                        }
                        label="I am a current enrolled student, faculty or staff affiliated with Carnegie Mellon University since June 1, 2024"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAgreedInformation}
                                onChange={handleisAgreedInformationChange}
                                name="agreementCheckbox"
                            />
                        }
                        label="I have read and understand the information above."
                    />
                     <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAgreedParticipation}
                                onChange={handleisAgreedParticipationChange}
                                name="agreementCheckbox"
                            />
                        }
                        label="I want to participate in this research and utilize the Non-Sensor-Based Parking Research Pilot Program."
                    />
                    <Button
                        onClick={handleContinue}
                        variant="contained"
                        sx={{ mt: 2 }}
                        disabled={!isAgreedAge || !isAgreedCmuMemeber || !isAgreedInformation || !isAgreedParticipation}
                    >
                        Continue
                    </Button>
                    
                </Box>
            </Modal>
    
            {isNotificationVisible && (
                <Alert
                    severity="success"
                    sx={{ position: 'fixed', bottom: 20, left: '50%', transform: 'translateX(-50%)' }}
                >
                    {notificationMessage}
                </Alert>
            )}
        </Container>
    );

   
}
