import React from 'react';
import { SideBar } from './SideBar';

export function Notifications() {
  return (
    <div>
        <SideBar></SideBar>
        <h1>Notifications</h1>
    </div>
  );
}
