import React, { useEffect, useState } from 'react';
import { SideBar } from "../SideBar";
import SpotCard from './SpotCard';
import { useHistory } from 'react-router-dom';
import './report.css';
import { fetchGet, uploadData } from '../request';

export function Report(props) {
    const [parkingLot, setParkingLot] = useState("Morewood test 1");
    const [spots, setSpots] = useState([]);
    const [didUpdate, setDidUpdate] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    
    // Define the start and end hours for active time (example: 9 AM to 5 PM)
    const startHour = process.env.REACT_APP_START_HOUR;
    const endHour = process.env.REACT_APP_END_HOUR;
    console.log(`Start Hour: ${startHour}, End Hour: ${endHour}`);

    const history = useHistory();

    useEffect(() => {
        if (!didUpdate) {
            fetchGet('/api/account/profile', {}, updateCallback);
        }
    }, [didUpdate]);

    const updateCallback = (data) => {
        setUserInfo(data);
        setDidUpdate(true);
    }

    const fetchSpots = () => {
        fetchGet('/api/report/spots', { "parkingLot": parkingLot }, fetchSpotsCallback);
    }

    const fetchSpotsCallback = (data) => {
        if ("success" in data) {
            setSpots(data["spots"]);
        }
    }

    useEffect(() => {
        if (!didUpdate) {
            fetchSpots();
            setInterval(fetchSpots, 3000000);
            setDidUpdate(true);
        }
    }, [didUpdate]);

    const navigateToReportHistory = () => {
        history.push('/reporthistory');
    };

    const rows = [];
    for (let i = 0; i < spots.length; i += 6) {
        rows.push(spots.slice(i, i + 6));
    }

    // Utility function to check if the current time is within active hours
    const isWithinActiveHours = () => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours(); // Get current hour in 24-hour format

        const currentDay = currentTime.getDay();
        const isWeekday = currentDay >= 1 && currentDay <= 5;
        const isActiveHour = currentHour >= startHour && currentHour < endHour;
        return isWeekday && isActiveHour;
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-3">
                    <SideBar username={userInfo.username} />
                </div>
                <div className="col-md-8">
                    <div className="mt-4 pt-3 mr-4 pr-4 border-bottom d-flex justify-content-between align-items-center">
                        <div>
                            <h4>Report Violation</h4>
                            <p>Report a violation you saw to get a $3 coupon reward</p>
                        </div>
                        <span className="report-history-link" onClick={navigateToReportHistory}>Report History &gt;</span>
                    </div>
                    <div className="d-flex mt-4 mb-4 align-items-center">
                        <span>Select Parking Lot:</span>
                        &nbsp;&nbsp;&nbsp;
                        <select className="form-control form-control-md" onChange={(e) => { setParkingLot(e.target.value) }}>
                            <option>Morewood test 1</option>
                        </select>
                    </div>
                    <div className="spot-container">
                        {/* Check if the current time is within the active hours */}
                        {isWithinActiveHours() ? (
                            rows.map((row, rowIndex) => (
                                <div key={rowIndex} className="spot-row">
                                    {row.map((item, index) => (
                                        <div key={index} className="spot-col">
                                            <SpotCard 
                                                parkingLot={parkingLot} 
                                                spot={item.name} 
                                                plate={item.plate} 
                                                userInfo={userInfo} 
                                                reservation={item.reservation} 
                                                uploadData={uploadData} 
                                            />
                                        </div>
                                    ))}
                                    {row.length < 6 && [...Array(6 - row.length)].map((_, index) => (
                                        <div key={`empty-${index}`} className="spot-col"></div>
                                    ))}
                                </div>
                            ))
                        ) : (
                            <p>Report are only available during active hours.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Report;
