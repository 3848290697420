import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { fetchPost } from '../request';
import './history.css';

export function UpcomingCard(props) {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleReschedule = () => {
        const data = {
            username: localStorage.getItem('username'),
            action: 'cancel',
            tranId: props.id,
        };

        fetchPost('/api/reservation/action', data, (result, error) => {
            if (result) {
                props.updateHandle();
                setShowModal(false);
                alert('Reservation canceled. Please rebook.');
                // Redirect to the booking page if necessary
            } else {
                alert(error);
            }
        });
    };


    const handleCancelReservation = () => {
        const data = {
            username: localStorage.getItem('username'),
            action: 'cancel',
            tranId: props.id,
        };

        fetchPost('/api/reservation/action', data, (result, error) => {
            if (result) {
                props.updateHandle();
                setShowModal(false);
                if(result.error){
                    alert('Reservations cannot be canceled within 15 minutes of the start time.');
                }
                else{
                    alert('Reservation canceled successfully');
                }
            } else {
                alert(error);
            }
        });
    };


    return (
        <>
            <div className="history-card" onClick={handleShowModal}>
                <div className="history-card-date">
                    <p>{props.date}</p>
                </div>
                <div className="history-card-info">
                    <p><strong>Spot {props.spot}</strong> {props.parkingLot}</p>
                    <p>{props.startTime} - {props.endTime}</p>
                </div>
                <div className="history-card-price">
                    <p>${props.price}</p>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Reservation Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Date:</strong> {props.date}</p>
                    <p><strong>Spot:</strong> {props.spot}</p>
                    <p><strong>Parking Lot:</strong> {props.parkingLot}</p>
                    <p><strong>Reservation Time:</strong> {props.startTime} - {props.endTime}</p>
                    <p><strong>Charge:</strong> ${props.charge}</p>
                    <p><strong>Reservation Fee:</strong> ${props.reservationFee}</p>
                    {props.isCompleted && <p><strong>Status:</strong> Completed</p>}
                    {props.isCanceled && <p><strong>Status:</strong> Canceled</p>}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger modal-button" onClick={handleReschedule}>Reschedule Reservation</button>
                    <button className="btn btn-danger modal-button" onClick={handleCancelReservation}>Cancel Reservation</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UpcomingCard;
