import React from 'react';
import { NavLink } from 'react-router-dom';

export function NavBar() {
  return (
    <div>
    <ul className="nav nav-tabs">
        <li className="nav-item">
            <NavLink to="/activehome" className="nav-link" activeClassName="active">Active</NavLink>
        </li>
        <li className="nav-item">
            <NavLink to="/upcominghome" className="nav-link" activeClassName="active">Upcoming</NavLink>
        </li>
        <li className="nav-item">
            <NavLink to="/historyhome" className="nav-link" activeClassName="active">History</NavLink>
        </li>
        </ul>
    </div>
  );
}
