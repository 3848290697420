import React from 'react';
import { fetchPost } from '../request';
import './extendform.css';
//cmnt tmp
const CheckoutModal = ({ show, handleClose, confirmPrompt, action, reservation, setActionPrompt, handleActionShow, handleConfirmClose }) => {
    const handleCheckOut = () => {
        const params = {
            'username': localStorage.getItem('username'),
            'tranId': reservation.id,
            'action': 'check out',
            'checkInTime': reservation.checkInTime,
            'scheduledLeaveTime': reservation.scheduledLeaveTime,
            'checkOutTime': new Date(Date.now()),
            'parkingLot': reservation.parkingLot
        };

        const checkoutCallback = (data) => {
            console.log(data)
            if ('error' in data) {
                setActionPrompt(<p>{data['error']}</p>);
                handleActionShow();
            } else {
                setActionPrompt(<div>
                    <p>You successfully checked out!</p>
                    {data['refund'] > 0 && <p>
                        <br /> The refund:
                        <br /> Refund rate for early leave: {data['refundRate']}
                        <br /> Effective refund time: {data['refundTime'] / 60} hrs
                        <br /> Refund Amount: <strong>${data['refund']}</strong>
                        <br /> {data['refundRate']} * {data['price']}/hrs * {data['refundTime'] / 60} hrs = ${data['refund']} 
                        <br /> The refund will be returned to your balance within two workdays.
                    </p>}
                </div>);
                handleActionShow();
            }
        };

        fetchPost('/api/reservation/action', params, checkoutCallback);
    };

    return (
        <div className={`modal ${show ? 'd-block' : 'd-none'}`} onClick={handleClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2>Check out this session?</h2>
                </div>
                <div className="modal-body">
                    {confirmPrompt}
                </div>
                <div className="modal-footer">
                    <button className="cancel" onClick={handleClose}>Close</button>
                    <button className="confirm" onClick={() => {
                        if (action === 'checkout') handleCheckOut();
                        handleConfirmClose();
                    }}>Confirm</button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutModal;
