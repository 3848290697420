import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../HomePage/history.css';

export function ReportCard(props) {
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <div className="history-card" onClick={handleShowModal}>
                <div className="history-card-date">
                    <p>{props.date}</p>
                </div>
                <div className="history-card-info">
                    <p><strong>Spot {props.spot}</strong> {props.parkingLot}</p>
                    <p>{props.time}</p>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Report Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Date:</strong> {props.date}</p>
                    <p><strong>Spot:</strong> {props.spot}</p>
                    <p><strong>Parking Lot:</strong> {props.parkingLot}</p>
                    <p><strong>Reported Time:</strong> {props.time}</p>
                    <p><strong>Reported By:</strong> {props.username}</p>
                    <p><strong>Email:</strong> {props.email}</p>
                    <p><strong>Phone:</strong> {props.tel}</p>
                    <p><strong>Plate:</strong> {props.plate}</p>
                    <p><strong>Report Status:</strong> {props.report_status}</p>
                    {props.pic && <img src={props.pic} alt="Report" style={{ width: '100%' }} />}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
